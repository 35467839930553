import classNames from "classnames";
import PropTypes from "prop-types";
import { React, useRef, useState } from "react";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";
import emailjs from "@emailjs/browser";
const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );
  const [formStatus, setFormStatus] = useState(true);

  const form = useRef();
  console.log(formStatus);
  // Sending Email
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_3w4liy3", "enquiry", form.current, "woA_xFrPH2SZu_G_w")
      .then(
        (result) => {
          console.log(result.text);
          // Clear all input field values
          form.current.reset();
          setFormStatus(true);
          alert(
            " We've received your message. We will get in touch with you shortly!"
          );
          //toast.success("Email send Successfully");
        },
        (error) => {
          console.log(error.text);
          alert("Please try again! Something went wrong");
          setFormStatus(false);
          //toast.error(error.text);
        }
      );
  };
  return (
    <section id="cta" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <div className="cta-slogan">
            <h3 className="m-0">Need a callback?</h3>
          </div>
          <div className="cta-action">
          <Input id="newsletter" type="tel" label="Request Callback" labelHidden hasIcon="right" placeholder="Your email address">
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>
          </div> */}
          {!formStatus ? (
            <div className="mb-16">
              We've received your message. We will get in touch with you
              shortly!
            </div>
          ) : (
            <form className="bg-white" ref={form} onSubmit={sendEmail}>
              <fieldset>
                <div className="mb-16">
                  <Input
                    type="text"
                    placeholder="Name"
                    label="Name"
                    name="from_name"
                    required
                  />
                </div>
                <div className="mb-16">
                  <Input
                    type="email"
                    placeholder="Email"
                    label="Email"
                    required
                    name="from_email"
                  />
                </div>
                <div className="mb-16">
                  <Input
                    type="textarea"
                    placeholder="Your message"
                    label="Your message"
                    required
                    name="message"
                  />
                </div>
                <div className="mt-24">
                  <div className="button-group">
                    <Button color="primary">Submit</Button>
                  </div>
                </div>
              </fieldset>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
